(function(){
  'use strict';

  angular
  .module('app')
  .component('modalDrawLimite', {
    templateUrl: 'app/components/loterias/modalLimitesDraw.html',
    controller: ModalCreateDrawLimite,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCreateDrawLimite.$inject = ['toastr','loteriasService'];

  function ModalCreateDrawLimite(toastr, $loteriasService) {
    var vm = this;
    vm.cancel = cancel;
    vm.send = false;

    vm.$onInit = function(){
      vm.sorteo = vm.resolve.sorteo;
      if(vm.sorteo.limite_sorteos.length > 0){
        vm.currencies = _.map(vm.sorteo.limite_sorteos, function(data){
          return {
            id: data.moneda_id,
            currency: data.currency,
            limite: data.limite,
            limite_triple: data.limite_triple
          }
        })
      }else{
        vm.currencies = vm.resolve.currencies;
      }
    }

    vm.save = function(saveAndReplicate){
      var data = _.reduce(vm.currencies, function(memo, data){
        memo.push({
          limite_triple: data.limite_triple,
          limite: data.limite,
          moneda_id: data.id,
          currency: data.currency,
          sorteo_id: vm.sorteo.id
        })
        return memo;
      }, [])

      $loteriasService.saveLimites({duplicate: saveAndReplicate, limites: data, sorteo_id: vm.sorteo.id})
      .then(function(response){
        toastr.success('Limites guardados correctamente', 'Éxito');
        vm.send = true;
        vm.close({$value: vm.send});
      });
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
