(function(){
  'use strict';

  angular
  .module('app')
  .component('loteriaDetail', {
    templateUrl: 'app/components/loterias/details.html',
    controller: LoteriaDetailsController
  })

  LoteriaDetailsController.$inject = ['loteriasService', '$uibModal','toastr','$rootScope','miscelaneos','_','$window','$stateParams'];


  function LoteriaDetailsController($loteriasService,$uibModal,toastr, $rootScope, $miscelaneos, _, $window, $stateParams) {
    var vm = this

    vm.$onInit = function(){
      vm.loading = true;
      $loteriasService.showLoteria($stateParams.id)
      .then(function(res){
        vm.loteria = res;
        vm.loteria.listAnimalitos = _.reduce(res.animalitos, function(memo, value, key){
          memo.push({
            number: key,
            nombre: value
          });
          return memo;
        },[])
        vm.loading = false;
      })
    }

    vm.save = function(){
      vm.send = true;
      vm.loteria._method = 'PUT';
      vm.loteria.animalitos = null;
      vm.loteria.animalitos = _.object(_.map(vm.loteria.listAnimalitos, _.values))
      delete vm.loteria.listAnimalitos;
      $loteriasService.updateLoteria(vm.loteria.id, vm.loteria)
      .then(function(res){
        vm.send = false;
        vm.$onInit();
      }, function(err){
        vm.send = false;
      })
    }

    $rootScope.$on('loterias', function(){
      vm.$onInit();
    })

    $rootScope.$emit('webLayout', {data: {title: 'Loterias', subtitle: 'Detalle de loteria', previewPage: 'Dashboard', currentPage:'Loteria'}});
  }
})();
