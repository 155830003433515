(function(){
  'use strict';

  angular
  .module('app')
  .component('noWinningModal', {
    templateUrl: 'app/components/noWinning/noWinningModal.html',
    controller: ModalCreateRaffleNoWinning,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCreateRaffleNoWinning.$inject = ['toastr','noWinningService','moment','structureService'];

  function ModalCreateRaffleNoWinning(toastr, $noWinningService, moment, $structureService) {
    var vm = this;
    vm.cancel = cancel;
    vm.loadSorteos = loadSorteos;
    vm.send = false;
    vm.raffle = {detail: []};

    vm.$onInit = function(){
      vm.structureTypes = vm.resolve.structureTypes;
      vm.companies = vm.resolve.companies;
      vm.currencies = vm.resolve.currencies;
      vm.typeAwards = [{value: 1, key: 'Metalico'}, {value: 2, key: 'Especie'}];
      vm.statuses = [{key: 'Activo', value: 'active'}, {key: 'Inactivo', value: 'inactive'}];
      vm.raffle = vm.resolve.raffle;
      if(vm.resolve.raffle){
        var parts = vm.raffle.raffle_date.split(' ');
        vm.dateParts = parts[0];
        vm.timeParts = parts[1];
        vm.dateSelected = new Date(moment(vm.raffle.raffle_date, 'YYYY-MM-DD HH:mm'));
        vm.timeSelected = new Date(moment(vm.raffle.raffle_date, 'YYYY-MM-DD HH:mm'));
        vm.statusSelected = _.findWhere(vm.statuses, {value: vm.raffle.status});
        console.log(vm.dateSelected, vm.timeSelected)
      }
    }

    vm.getStructurByType = function(){
      var params = {
        type: vm.structureTypeSelected.key,
        company_id: vm.companySelected.id
      }
      $structureService.getStructurebyType(params)
      .then(function(res){
        vm.structures = res;
      })
    }


    vm.save = function() {
      vm.send = true;
      vm.raffle.raffle_date = moment(vm.dateSelected).format('YYYY-MM-DD') + ' ' + moment(vm.timeSelected).format('HH:mm');
      if(vm.raffle.id){
        $noWinningService.noWinning.update({id: vm.raffle.id}, vm.raffle).$promise
        .then(function(res){
          toastr.success('Rifa actualizado con éxito');
          vm.close({$value: ''});
        })
      }else{
        vm.raffle.amount_awards = vm.raffle.detail.length;
        $noWinningService.noWinning.save(vm.raffle).$promise
        .then(function(res){
          toastr.success('Rifa creado con éxito');
          vm.close({$value: ''});
        })
      }
    }

    function cancel() {
        vm.dismiss();
    }

    function loadSorteos(loteriaID){
      console.log(loteriaID)
      vm.sorteos = _.findWhere(vm.loterias, {id: loteriaID}).sorteos;
      console.log(vm.sorteos)
    }

    vm.addAttr = function(){
      if(_.isNull(vm.raffle) || _.isUndefined(vm.raffle)){
        vm.raffle = { detail: [] };
      }else{
        vm.raffle.detail = vm.raffle.detail || [];
      }
      console.log(vm.raffle)
      vm.raffle.detail.push({
        detail: '',
        cost: 0,
        amount: 0,
        currency: '',
        description: ''
      })
    }



    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: true
    };

    vm.dateOptions = {
      dateDisabled: disabled,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }
  }
})();
