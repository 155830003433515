(function(){
  'use strict';

  angular
  .module('app')
  .component('structure', {
    templateUrl: 'app/components/structure/structure.html',
    controller: StructureController
  })

  StructureController.$inject = ['$uibModal','toastr','$rootScope','miscelaneos','_','$state','structureService','loteriasService'];


  function StructureController($uibModal,toastr, $rootScope, $miscelaneos, _, $state, $structureService,$loteriasService) {
    var vm = this;
    vm.send = false;
    vm.loadingData = true;
    vm.createStructure = createStructure;
    vm.editStructure = editStructure;
    vm.structures = [];
    vm.structure = {};
    vm.lotteries = [];
    vm.type_structures = [{id: 1, nombre: 'operadora'}, {id: 2, nombre: 'comercializador'}, {id: 3, nombre: 'agente'}, {id: 4, nombre: 'agencia'}, {id: 5, nombre: 'jugador'}]


    vm.$onInit = function(){
      $structureService.showStructurePrincipal({small: true})
      .then(function(res){
        vm.operadora = res;
      })
      $structureService.getStructures()
      .then(function(res){
        vm.structures = res
        _.forEach(vm.structures, function(structure){
          structure.limites = _.groupBy(structure.configuracion_limites, structure.sorteo_id)
        })
        vm.loadingData = false;
      })
    }

    vm.showStructureOperadora = function(){
      $structureService.showStructurePrincipal()
      .then(function(res){
      })
    }


    function createStructure() {
      var modal = $uibModal.open({
        animation: true,
        component: 'createStructureComponent',
        windowClass: 'createEstructure',
        size: 'lg',
        resolve: {
          type_structures: function(){
            return angular.copy(vm.type_structures)
          },
          structure: function() {
            return angular.copy(vm.structure)
          },
          structures: function(){
            return angular.copy(vm.structures)
          }
        }
      });

      modal.result.then(function(res){
        vm.structures.push(res);
        $state.go('showStructure',{id: res.id});
      }, function(err){
        toastr.error('Accion cancelada');
      });

    }

    function editStructure(structure) {
      vm.loadingData = true;
      var modal = $uibModal.open({
        animation: true,
        component: 'editStructureComponent',
        windowClass: 'editStructureComponent',
        size: 'lg',
        resolve: {
          type_structures: function(){
            return angular.copy(vm.type_structures)
          },
          structure: function(){
            return $structureService.showStructure(structure.id)
            .then(function(res){
              vm.loadingData = false;
              return angular.copy(res);
            })
          },
          structures: function(){
            return angular.copy(vm.structures)
          }
        }
      });

      modal.result.then(function(res){
      }, function(err){
        toastr.error('Accion cancelada');
      });

    }

    $rootScope.$emit('webLayout', {data: {title: 'Estructuras',subtitle: 'Administracion de estructuras',previewPage: 'Dashboard', currentPage:'Estructura'}});

    $rootScope.$on('structures', function(){
      vm.$onInit();
    })

  }

})();
