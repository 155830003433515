(function(){
  'use strict';

  angular
  .module('app')
  .component('loterias', {
    templateUrl: 'app/components/loterias/loterias.html',
    controller: LoteriasController
  })

  LoteriasController.$inject = ['loteriasService', '$uibModal','toastr','$rootScope','miscelaneos','_','$window'];


  function LoteriasController($loteriasService,$uibModal,toastr, $rootScope, $miscelaneos, _, $window) {
    var vm = this;
    vm.settingsLoteryDefault = settingsLoteryDefault;
    vm.settingsLotery = settingsLotery;
    vm.createLoterias = createLoterias;
    vm.editLoteria = editLoteria;
    vm.addSorteos = addSorteos;
    vm.createDraw = createDraw;
    vm.showDraws = false;
    vm.currentLoteria = {};
    vm.types = [];
    vm.newDraw = {};
    vm.turno = [{name: 'manana',turno: 'M'},{name: 'tarde',turno: 'T'},{name: 'noche',turno: 'N'}];
    vm.daysSelected = [];
    vm.daysOptions = ["lun","mar","mie","jue","vie","sab","dom"];
    vm.tipo_listas = ['A','B','C','Z'];


    vm.$onInit = function(){
      $miscelaneos.currency.query().$promise
      .then(function(res){
        vm.currencies = res;
      })
      $loteriasService.getLoterias(null)
      .then(function(res){
        vm.loterias = res;
      })
      
      vm.types = $miscelaneos.getTypes()

      $loteriasService.settingsLoteryDefault()
      .then(function(res){
        vm.settings = res;
      })

      $loteriasService.getCharities()
      .then(function(res){
        vm.charities = res;
      })
    }

    function createLoterias(plays){
      var modal = $uibModal.open({
        animation: true,
        component: 'createLoteriasComponent',
        windowClass: 'createLoteriasComponent',
        size: 'md',
        resolve: {
          charities: function(){
            return vm.charities;
          },
          currencies: function(){
            return vm.currencies
          }
        }
      });

      modal.result.then(function(res){
        toastr.success('Loteria creada con exito');
        vm.loterias.push(res)
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function createDraw(){
      var modal = $uibModal.open({
        animation: true,
        component: 'modalDraw',
        windowClass: 'modalDraw',
        size: 'lg',
        resolve:{
          daysOptions: function(){
            return angular.copy(vm.daysOptions)
          },
          types: function(){
            return angular.copy(vm.types)
          },
          turno: function(){
            return angular.copy(vm.turno)
          },
          loteria: function(){
            return angular.copy(vm.currentLoteria)
          }
        }
      });

      modal.result.then(function(res){
        vm.currentLoteria.sorteos.push(res);
      }, function(err){
        toastr.error('Accion Cancelada');
      });
    }


    function editLoteria(loteria){
      var modal = $uibModal.open({
        animation: true,
        component: 'editLoteriasComponent',
        windowClass: 'createLoteriasComponent',
        size: 'md',
        resolve: {
          lotery: function() {
            return angular.copy(loteria);
          },
          charities: function(){
            return vm.charities;
          },
          currencies: function(){
            return vm.currencies
          }
        }
      });

      modal.result.then(function(res){
        toastr.success('Loteria actualizada con exito');
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function settingsLotery(loteria){
      var modal = $uibModal.open({
        animation: true,
        component: 'settingsLoteryComponent',
        size: 'md',
        resolve: {
          lotery: function() {
            return angular.copy(loteria);
          }
        }
      });

      modal.result.then(function(res){
        toastr.success('Loteria: '+res.nombre+' actualizada con exito');
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function settingsLoteryDefault(){
      var modal = $uibModal.open({
        animation: true,
        component: 'settingsLoteryDefaultComponent',
        size: 'md',
        resolve: {
          lotery: function() {
            return angular.copy(vm.settings);
          }
        }
      });

      modal.result.then(function(res){
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }



    function addSorteos(loteria) {
      vm.showDraws = true;
      vm.currentLoteria = loteria;
      _.map(vm.currentLoteria.sorteos, function(sorteo){
        sorteo.daysOptions = ["lun","mar","mie","jue","vie","sab","dom"];
        return sorteo;
      })
      $rootScope.$emit('webLayout', {data: {title: 'Sorteos',subtitle: 'Administracion de sorteos',previewPage: 'Loteria', currentPage:'Sorteos'}});
    }

    vm.desactiveProduct = function(loteria){
      var mensaje = loteria.active ? 'Desactivar' : 'Activar'
      Swal({
        title: 'Esta seguro que '+ mensaje +' el producto y sus sorteos?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $loteriasService.desactiveProduct(loteria)
            .then(function(res){
              toastr.success('Loteria: '+loteria.nombre+' desactivada con exito');
              vm.$onInit();
              resolve();
            },function(err){
              swal('Error',err.data.message,'indo')
              reject('error');
            })
          });
        },
        allowOutsideClick: false,
      });
    }
    

    $rootScope.$on('loterias', function(){
      vm.$onInit();
    })

    $rootScope.$emit('webLayout', {data: {title: 'Loterias',subtitle: 'Administracion de loterias',previewPage: 'Dashboard', currentPage:'Loteria'}});


  }





})();
