(function () {
  angular
  .module('app')
  .factory('noWinningService', noWinning);
})();

noWinning.$inject = ['$http', 'url', '$resource'];

function noWinning($http, $url, $resource) {

  var noWinning = $resource($url.getApiUrl('/non_winning/:id.json'), { id: '@_id' }, { 'update': { method: 'PUT' } });

  return {
    noWinning: noWinning
  };
}
