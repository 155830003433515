(function(){
  'use strict';

  angular
  .module('app')
  .component('orderDraw', {
    templateUrl: 'app/components/order_draw/order_draw.html',
    controller: OrderDrawController
  })

  OrderDrawController.$inject = ['loteriasService', '$uibModal','toastr','$rootScope','miscelaneos','_','$window'];


  function OrderDrawController($loteriasService,$uibModal,toastr, $rootScope, $miscelaneos, _, $window) {
    var vm = this;
    vm.save = save;
    vm.showDraws = false;
    vm.orderingDraws = [];
    vm.loading = true;
    vm.$onInit = function(){
      $loteriasService.getLoterias('null')
      .then(function(res){
        vm.triples = _.where(angular.copy(res), {tipo: 'triples'});
        vm.animalitos = _.where(angular.copy(res), {tipo: 'animalitos'});
        vm.loading = false;
      })
      // $loteriasService.drawsByLotery()
      // .then(function(res){
      //   vm.sorteos = res;
      // })
    }

    vm.animalitosSortableOptions = {
      stop: function(e, ui) {
        _.map(vm.animalitos, function(item, index){
          item.orden = index +1
          return item;
        })
      }
    }

    vm.triplesSortableOptions = {
      stop: function(e, ui) {
        _.map(vm.triples, function(item, index){
          item.orden = index +1
          return item;
        })
      }
    }

    vm.sorteosSortableOptions = {
      stop: function(e, ui) {
        _.map(vm.sorteos, function(item, index){
          item.orden = index +1
          return item;
        })
      }
    }

    vm.saveAnimalitos = function(){
      vm.loading = true;
      _.map(vm.animalitos, function(item, index){
        item.orden = index +1
        return item;
      })
      $loteriasService.updateOrdenLoteries(vm.animalitos)
      .then(function(res){
        vm.loading = false;
        console.log(res)
        $rootScope.$emit('loterias',{});
        vm.close({$value: res.data});
      }, function(err){
        vm.loading = false;
      })
    }

    vm.saveTriples = function(){
      vm.loading = false;
      _.map(vm.triples, function(item, index){
        item.orden = index +1
        return item;
      })

      $loteriasService.updateOrdenLoteries(vm.triples)
      .then(function(res){
        vm.loading = false;
        console.log(res)
        $rootScope.$emit('loterias',{});
        vm.close({$value: res.data});
      }, function(err){
        vm.loading = false;
      })
    }

    
    
    



    function save(sorteo){
      vm.send = true;
      _.map(vm.sorteos, function(item, index){
        item.orden = index +1
        return item;
      })
      $loteriasService.updateOrderDraw({sorteos: vm.sorteos})
      .then(function(res){
        vm.send = false;
        toastr.success(res.message);
      }, function(err){
        vm.send = false;
        toastr.error('Ocurrio un error, consulte con soporte tecnico');
      })
    }

    $rootScope.$on('orderLoteries', function(){
      onInit();
    })

    $rootScope.$emit('webLayout', {data: {title: 'Sorteos',subtitle: 'Orden de los sorteos para la taquilla',previewPage: 'Dashboard', currentPage:'Loteria'}});

  }





})();
