(function(){
  'use strict';

  angular
  .module('app')
  .component('createLoteriasComponent', {
    templateUrl: 'app/components/loterias/modalCreate.html',
    controller: ModalCreateloteriaController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCreateloteriaController.$inject = ['toastr','loteriasService'];

  function ModalCreateloteriaController(toastr, $loteriasService) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    vm.lotery = {};

    vm.$onInit = function(){
      vm.charities = vm.resolve.charities;
      vm.currencies = vm.resolve.currencies;
    }

    function save() {
      vm.send = true;

      $loteriasService.saveLoteria(vm.lotery)
      .then(function(res){
        vm.send = false;
        vm.close({$value: res.data});
      }, function(err){
        vm.send = false;
        toastr.error(err.data.message);
      })
    }

    vm.setCurrencies = function(data){
      vm.lotery.currencies = _.pluck(vm.selectedCurrency, 'id')
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
