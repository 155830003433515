(function(){
  'use strict';

  angular
  .module('app')
  .component('createStructureComponent', {
    templateUrl: 'app/components/structure/createStructure.html',
    controller: ModalCreateStructureController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCreateStructureController.$inject = ['toastr','structureService','_','$rootScope','loteriasService','$timeout'];

  function ModalCreateStructureController(toastr, $structureService, _, $rootScope, $loteriasService, $timeout) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.nextStep = nextStep;
    vm.backStep = backStep;
    vm.calculateAmount = calculateAmount;
    vm.calculatePropina = calculatePropina;
    vm.calculatePercent = calculatePercent;
    vm.calculateAmountTriple = calculateAmountTriple;
    vm.send = false;
    vm.isOpen = true;
    vm.$onInit = function(){
      vm.structure = vm.resolve.structure;
      console.log(vm.structure)
      vm.type_structures = vm.resolve.type_structures;
      vm.structures = vm.resolve.structures;
      vm.structure.tipo_estructura = _.findWhere(vm.type_structures, {nombre: "comercializador"}).id
      $loteriasService.getLoterias()
      .then(function(res){
        vm.loteries = res;
        _.forEach(vm.structure.configuracion_limites, function(data){
          var loteria = _.findWhere(vm.loteries, {nombre: data.nombre});
          _.forEach(data.sorteos, function(sorteo){
            if(loteria){
              sorteo.porcentajeReference = parseFloat(_.findWhere(loteria.sorteos, {nombre: sorteo.nombre}).limite_terminal);
              sorteo.porcentajeReferenceTriple = parseFloat(_.findWhere(loteria.sorteos, {nombre: sorteo.nombre}).limite_triple);
              vm.calculateAmount(sorteo);
              vm.calculateAmountTriple(sorteo);
            }
          })
        })
      })
    };

    vm.steps = {
      stepOne: true,
      secondStep: false,
      thirdStep: false,
      fourStep: false
    }

    function nextStep(step){

      if(vm.steps.stepOne){
        vm.steps.stepOne = false;
        vm.steps.fourStep = true;
        return;
      }
    }

    function backStep(step){
      if(vm.steps.fourStep){
        vm.steps.stepOne = true;
        vm.steps.fourStep = false;
        return;
      }

    }

    function save() {
      if(vm.structure.username == undefined && vm.structure.password == undefined){
        swal('Error','Agregue los datos de usuario','error');
        return
      }
      vm.send = true;

      $structureService.saveStructure(vm.structure)
      .then(function(res){
        toastr.success(res.message);
        vm.close({$value: res.data});
        $rootScope.$emit('structures', {});
      }, function(err){
        vm.send = false;
        toastr.error(err.data.message);
      })

    }

    function calculatePercent(data){
      data.porcentaje = data.limite / data.porcentajeReference * 100;
      setAmountPretty(data)
    }

    function calculateAmount(data){
      data.limite = data.porcentajeReference * data.porcentaje / 100;
      setAmountPretty(data)
    }

    function setAmountPretty(data){
      data.pretty_amount = accounting.formatMoney(data.limite, 'Bs. ','2','.',',');
    }

    function calculatePropina(conf){
      var monto = conf.comision_premio_mayor * conf.amount_reference / 100;
      conf.monto_participacion = accounting.formatMoney(monto, '','2','.',',');
    }

    //triple
    function calculateAmountTriple(data){
      data.limite_triple = data.porcentajeReferenceTriple * data.porcentaje_triple / 100;
      setAmountPrettyTriple(data)
    }

    function setAmountPrettyTriple(data){
      data.pretty_amount_triple = accounting.formatMoney(data.limite_triple, 'Bs. ','2','.',',')
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
