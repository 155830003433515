(function(){
  'use strict';

  angular
  .module('app')
  .component('currency', {
    templateUrl: 'app/components/currency/currency.html',
    controller: CurrencyController
  })

  CurrencyController.$inject = ['$uibModal','toastr','$rootScope','miscelaneos','_','$window'];


  function CurrencyController($uibModal,toastr, $rootScope, $miscelaneos, _, $window) {
    var vm = this;
    vm.loading = true;
    
    
    vm.$onInit = function () {
      $miscelaneos.currency.query().$promise
      .then(function(res){
        vm.monedas = res;
        vm.loading = false;
      });
    }

    vm.save = function(moneda){
      vm.send = true;
      $miscelaneos.currency.update({id: moneda.id}, moneda).$promise
      .then(function(res){
        vm.send = false;
        toastr.success('Precio actualizado correctamente');
        $onInit();
      });
    }

    $rootScope.$on('orderLoteries', function(){
      onInit();
    })

    $rootScope.$emit('webLayout', {data: {title: 'Sorteos',subtitle: 'Orden de los sorteos para la taquilla',previewPage: 'Dashboard', currentPage:'Loteria'}});

  }





})();
