(function(){
  'use strict';

  angular
  .module('app')
  .component('noWinning', {
    templateUrl: 'app/components/noWinning/noWinning.html',
    controller: NoWinningController
  })

  NoWinningController.$inject = ['noWinningService', '$uibModal', 'structureService', '$rootScope', 'miscelaneos', '_', 'toastr',];


  function NoWinningController($noWinningService, $uibModal, $structureService, $rootScope, $miscelaneos, _, $toastr) {
    var vm = this;
    vm.loading = false;
    vm.loterias = [];

    vm.$onInit = function(){
      vm.loading = true;
      $noWinningService.noWinning.query().$promise
      .then(function(res){
        vm.noWinnings = [];
        vm.noWinnings = res;
        vm.loading = false;
      })

      $structureService.getStructuresType()
      .then(function(res){
        vm.structureTypes = res;
      })

      $structureService.companies()
      .then(function(res){
        vm.companies = res;
      })

      $miscelaneos.currency.query().$promise
      .then(function(res){
        vm.monedas = res;
      });
    }


    vm.createRaffle = function(){
      var modal = $uibModal.open({
        animation: true,
        component: 'noWinningModal',
        windowClass: 'createLoteriasComponent',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          structureTypes: function(){
            return angular.copy(vm.structureTypes)
          },
          companies: function(){
            return angular.copy(vm.companies)
          },
          currencies: function(){
            return angular.copy(vm.monedas)
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }


    vm.editRaffle = function(raffle){
      var modal = $uibModal.open({
        animation: true,
        component: 'noWinningModal',
        windowClass: 'createLoteriasComponent',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          raffle: function(){
            return angular.copy(raffle)
          },
          structureTypes: function(){
            return angular.copy(vm.structureTypes)
          },
          companies: function(){
            return angular.copy(vm.companies)
          },
          currencies: function(){
            return angular.copy(vm.monedas)
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      }, function(err){
        vm.$onInit();
      });
    }

 

    

    

    

    $rootScope.$emit('webLayout', {data: {title: 'No Ganadores',subtitle: '',previewPage: 'Dashboard', currentPage:'No Ganadores'}});
  }

})();
