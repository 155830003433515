(function () {
  angular
  .module('app')
  .factory('structureService', structureService);
})();

structureService.$inject = ['$http', 'url'];

function structureService($http, $url) {

  function hashToParams(params){
    var params_url = '';

    Object.keys(params).forEach(function(item, index) {
      if (index == 0 && params[item] != null) {
        params_url += '?' + item + '=' + params[item];
      } else if (index != 0 && params[item] != null) {
        params_url += '&' + item + '=' + params[item];
      }
    });

    return params_url;
  }

  function getStructures(){
    return $http.get($url.getApiUrl('/estructura'))
    .then(function(res){
      return res.data;
    })
  }
  function getStructuresType(){
    return $http.get($url.getApiUrl('/estructura/extract_types'))
    .then(function(res){
      return res.data;
    })
  }
  function companies(){
    return $http.get($url.getApiUrl('/estructura/companies'))
    .then(function(res){
      return res.data;
    })
  }

  function getStructurebyType(data){
    return $http.get($url.getApiUrl('/estructura/type_structure'+hashToParams(data)))
    .then(function(res){
      return res.data;
    })
  
  }

  function saveStructure(data){
    return $http.post($url.getApiUrl('/estructura'), data)
    .then(function(res){
      return res.data;
    })
  }
  function showStructure(id){
    return $http.get($url.getApiUrl('/estructura/'+id))
    .then(function(res){
      return res.data;
    })
  }
  function updateStructure(id, data){
    return $http.put($url.getApiUrl('/estructura/'+id), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateStructureConfiguration(data){
    return $http.post($url.getApiUrl('/estructura/update_configuracion'),data)
    .then(function(res){
      return res.data;
    })
  }

  function showStructurePrincipal(data){
    return $http.get($url.getApiUrl('/estructura/show_structura_principal?small='+data.small))
    .then(function(res){
      return res.data;
    })
  }

  function updateLimiteStructure(data){
    return $http.post($url.getApiUrl('/estructura/update_limites'), data)
    .then(function(res){
      return res.data;
    })
  }
  function updateUser(id, data){
    return $http.put($url.getApiUrl('/usuarios/'+id), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateConfigPremio(data){
    return $http.post($url.getApiUrl('/estructura/update_config_premio'), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateTipoProducto(id, data){
    return $http.post($url.getApiUrl('/estructura/update_producto_estructura?estructura_id='+id), data)
    .then(function(res){
      return res.data;
    })
  }
  function getComercializadoras(id, data){
    return $http.get($url.getApiUrl('/estructura/get_comercializadoras'))
    .then(function(res){
      return res.data;
    })
  }
  function updateConfigLoteriaEstructura(id, data){
    return $http.post($url.getApiUrl('/estructura/update_config_loteria_estructura?estructura_id='+id), data)
    .then(function(res){
      return res.data;
    })
  }



  return {
    getStructures: getStructures,
    saveStructure: saveStructure,
    updateStructure: updateStructure,
    updateStructureConfiguration: updateStructureConfiguration,
    updateLimiteStructure: updateLimiteStructure,
    updateUser: updateUser,
    updateConfigPremio: updateConfigPremio,
    updateTipoProducto: updateTipoProducto,
    getComercializadoras: getComercializadoras,
    showStructure: showStructure,
    updateConfigLoteriaEstructura: updateConfigLoteriaEstructura,
    showStructurePrincipal: showStructurePrincipal,
    getStructuresType: getStructuresType,
    getStructurebyType: getStructurebyType,
    companies: companies
  };
}
